<template>
    <b-overlay :show="show" rounded="sm">
        <b-card-code no-body title="Update administrator">
            <b-row class="pb-2">
                <b-col md="6">
                    <div class="px-2">
                    <!-- form -->
                    <validation-observer ref="adminUpdateForm">
                        <b-form
                        class="auth-register-form mt-2"
                        @submit.prevent="updateAdmin"
                        >
                        <!-- fullname -->
                        <b-form-group>
                            <validation-provider
                            #default="{ errors }"
                            name="Full name"
                            vid="fullname"
                            rules="required"
                            >
                            <b-form-input
                                id="register-fullname"
                                v-model="fullname"
                                name="register-fullname"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Full Name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- username -->
                        <b-form-group>
                            <validation-provider
                            name="Username"
                            vid="username"
                            rules="required"
                            >
                            <b-form-input
                                id="register-username"
                                v-model="username"
                                name="register-username"
                                :state="errorExists"
                                placeholder="Username"
                                @keyup='checkUsername()'
                            />
                            <small class="text-danger">{{checkUsernameError}}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- email -->
                        <b-form-group>
                            <validation-provider
                            #default="{ errors }"
                            name="Email"
                            vid="email"
                            rules="required|email"
                            >
                            <b-form-input
                                id="register-email"
                                v-model="userEmail"
                                name="register-email"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Email"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- password -->
                        <b-form-group >
                            <validation-provider
                            #default="{ errors }"
                            name="Password"
                            rules="password"
                            >
                            <b-input-group
                                class="input-group-merge"
                                :class="errors.length > 0 ? 'is-invalid' : null"
                            >
                                <b-form-input
                                id="password"
                                v-model="password"
                                :type="passwordFieldType"
                                :state="errors.length > 0 ? false : null"
                                class="form-control-merge"
                                name="password"
                                placeholder="Password"
                                @keyup="checkPassword()"
                                />
                                <b-input-group-append is-text>
                                <feather-icon
                                    :icon="passwordToggleIcon"
                                    class="cursor-pointer"
                                    @click="togglePasswordVisibility"
                                />
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- confirm password -->
                        <b-form-group>
                            <validation-provider
                            #default="{ errors }"
                            name="Confirm Password"
                            rules="confirmed:Password"
                            >
                            <b-input-group
                                class="input-group-merge"
                                :class="errors.length > 0 ? 'is-invalid' : null"
                            >
                                <b-form-input
                                id="password-confirm"
                                v-model="cPassword"
                                :type="cPasswordFieldType"
                                :state="errors.length > 0 ? false : null"
                                class="form-control-merge"
                                name="password-confirm"
                                placeholder="Confirm password"
                                @keyup="checkPassword()"
                                />
                                <b-input-group-append is-text>
                                <feather-icon
                                    :icon="cPasswordToggleIcon"
                                    class="cursor-pointer"
                                    @click="toggleCPasswordVisibility"
                                />
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group>
                            <v-select
                                v-model="selected_permissions"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                multiple
                                label="name"
                                :options="permissions"
                                placeholder="Permissions"
                            />
                        </b-form-group>
                        
                        <b-form-group>
                            <v-select
                                v-model="selected_roles"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                multiple
                                label="name"
                                :options="roles"
                                placeholder="Roles"
                            />
                            <small class="text-danger">{{ rolesError }}</small>
                        </b-form-group>

                        <b-button
                            variant="primary"
                            type="submit"
                            :disabled="btnDisabled"
                        >
                            Update account
                        </b-button>
                        <div
                            v-if="loading"
                            class="d-flex justify-content-center mt-2"
                        >
                            <b-spinner variant="primary" label="Loading..." />
                        </div>
                        </b-form>
                    </validation-observer>
                    </div>
                </b-col>
            </b-row>
        </b-card-code>
    </b-overlay>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BOverlay, BButton, BFormInput, BForm, BFormGroup, BRow, BCol, BInputGroup, BInputGroupAppend } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email } from "@validations";
import { BSpinner } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from 'vue-select'

export default {
    components: {
        BOverlay,
        BCardCode,
        BRow,
        BCol,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        ValidationProvider,
        ValidationObserver,
        BInputGroupAppend,
        BSpinner,
        vSelect
    },
    data() {
        return {
            show: true,
            fullname: "",
            username: "",
            userEmail: "",
            password: "",
            cPassword: "",
            selected_permissions: null,
            permissions: [],
            selected_roles: null,
            roles: [],

            // validation
            required,
            email,

            btnDisabled: false,
            loading: false,
            isAvailableUsername: 1,
            errors: [],
            rolesError: "",
            slugFlag: false,
            slugString: "",

            // Toggle Password
            passwordFieldType: 'password',
            cPasswordFieldType: 'password',
        }
    },
    computed: {        
        passwordToggleIcon() {
            return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
        },
        cPasswordToggleIcon() {
            return this.cPasswordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
        },
        checkUsernameError() {
            return this.errors[0]
        },
        errorExists() {
            if(this.username.length > 0) {
                if(this.isAvailableUsername == 0) {
                  this.errors.push('That username already exists')
                  return false
                } else {
                  this.errors = []
                  return null
                }
            } else {
                this.errors.push('The Username field is required')
                return false
            }
        }
    },
    created() {
        //Return permissions list
        this.$http.get("/api/auth/admin/permissions")
            .then((res) => {
                if(res != undefined) {
                    let preparedPermissions = [];
                    for(let i=0; i<res.data.length; i++) {
                        preparedPermissions.push(res.data[i].name)
                    }
                    this.permissions = res.data
                }
            })
            .catch((err) => {
                console.log(err)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })
        
        //Return roles list
        this.$http.get("/api/auth/admin/roles")
            .then((res) => {
                if(res != undefined) {
                    let preparedRoles = [];
                    for(let i=0; i<res.data.length; i++) {
                        preparedRoles.push(res.data[i].name)
                    }
                    this.roles = res.data
                }
            })
            .catch((err) => {
                console.log(err)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })
    },
    mounted() {
        // Show overlay
        this.show = true

        let id = this.$route.params.id
        
        //Return permissions list
        this.$http.get("/api/auth/admin/users/" + id)
            .then((res) => {
                this.fullname = res.data.name,
                this.userEmail = res.data.email,
                this.selected_permissions = res.data.permissions,
                this.selected_roles = res.data.roles,
                this.username = res.data.username

                // Hide overlay
                this.show = false
            })
            .catch((err) => {
                console.log(err)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });

                // Hide overlay
                this.show = false
            })
    },
    methods: {
        checkPassword() {
            if(this.password.length > 0 || this.cPassword.length > 0) {
                if(this.password == this.cPassword) {
                    this.btnDisabled = false
                } else {
                    this.btnDisabled = true
                }
            } else {
                this.btnDisabled = false
            }
        },
        // Toggle password visibility
        togglePasswordVisibility() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
        },
        toggleCPasswordVisibility() {
            this.cPasswordFieldType = this.cPasswordFieldType === 'password' ? 'text' : 'password'
        },

        // Request to update admin
        updateAdmin() {
            // Show overlay
            this.show = true

            // Get param from url
            let id = this.$route.params.id

            this.rolesError = ""

            let admin = {
                "name": this.fullname,
                "email": this.userEmail,
                "permissions": this.selected_permissions,
                "roles": this.selected_roles,
                "username": this.username,
                "role": "admin"
            }

            // If password is changed
            if(this.password.length > 0 && this.cPassword.length > 0) {
                admin.password = this.password
                admin.c_password = this.cPassword
            }

            this.$refs.adminUpdateForm.validate().then(success => {
                if (success) {
                    this.btnDisabled = false

                    this.$http.patch("/api/auth/admin/users/" + id, admin)
                    .then((res) => {                        
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Admin successfully updated",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });

                        // Hide overlay
                        this.show = false
                    })
                    .catch((error) => {
                        // Hide overlay
                        this.show = false

                        let errorMsg = "There was an error!"
                        
                        if(error.response != undefined) {
                            errorMsg = error.response.data.errors.email[0]
                        } else {
                            errorMsg = error.message
                        }
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: errorMsg,
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });

                    });
                } else {
                    this.btnDisabled = true
                }
            });

        },
        checkUsername() {
            let username = this.username.trim()

            if(username != '' && !username.includes(" ")) {
                this.$http.get(`/api/user/username/${username}`)
                .then((res) => {
                    this.isAvailableUsername = 1
                    this.errors = []
                }).catch((error) => {
                    this.isAvailableUsername = 0
                    if(error.response.data.message == "Username is taken") {
                        this.errors[0] = 'That username already exists'
                    } else if (error.response.data.message == "The given data was invalid.") {
                        this.errors[0] = 'Swear and bad words not allowed'
                    } else {
                        this.errors[0] = 'Something is wrong, please try again.'
                    }
                })
            }

            if(username.includes(" ")) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Username must not contain spaces!",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            }

        },
    },
}
</script>

<style scoped>
    option[disabled] { background-color: #D0D0D0; }
</style>

<style>
    .vs__selected {
        background-color: #59949c!important;
    }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
